import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { CartContext } from '../context/CartContext';
import QuantityBtn from './QuantityBtn';
import Title from './Title'


export default function Checkout() {
  let { cartItems } = useContext(CartContext)
  let cartNotEmpty = cartItems.length > 0 ? true : false
  let grandTotal = cartItems.reduce((total, product) => {
    return total += product.price * product.quantity
  }, 0)
  const freeshippingPrice = 99
  return (

    <div>
      <Title mainTitle="Checkout" />
      {/* <Link to="/" ></Link> */}
      {
        !cartNotEmpty &&
        <div id="cartSection">
          no cartitem

          <Link to="/">Go to Product Page</Link>
        </div>
      }
      {
        cartNotEmpty &&
        <div>
          <div id="cartSection">
            {
              cartItems.map((product) => (
                <div key={product.id}>

                  <img src={process.env.PUBLIC_URL+'/img/' + product.image} width="200px" ></img>
                  {product.name}
                  {product.description}
                  {product.price}
                  購買數量{product.quantity}
                  <QuantityBtn productInfo={product}/>
                </div>
              ))
            }
          </div>
          <div id="amountSection">
            <div>全部貨品總共</div>
            <div>{grandTotal}</div>
            {
              grandTotal >= freeshippingPrice ?
                <div>You get free shipping</div> : <div>Need buy more {freeshippingPrice - grandTotal}</div>
            }
          </div>
        </div>
      }

    </div>




  )
}
