
import React, { useContext, useState,useEffect } from 'react'
import { CartContext } from '../context/CartContext'
import useLocalStorage from '../util/useLocalStorage'

export default function QuantityBtn({productInfo}) {

const {cartItems,setCartItems} = useContext(CartContext)
const [localCartItem, setLocalCartItem] = useLocalStorage('cart', '')
 /* Check 購物車內有冇該product */
 let productIndexInCart = cartItems.findIndex((element)=>{
    return element.id === productInfo.id
 })
 
 //findIndex
//   如果係購物車內找到該件product => return index
//    if no product return -1
useEffect(() => {
    setLocalCartItem(cartItems)
}, [cartItems])

    let [numInCart, setNumInCart] = useState(

        ( productIndexInCart === -1 ? 0 : cartItems[productIndexInCart].quantity)
    );


    const handleAdd = () => {
      
        if(productIndexInCart === -1 ){
            /* Need add new index */
            setCartItems(
                [{
                id:productInfo.id,
                name:productInfo.name,
                image:productInfo.image,
                price:productInfo.price,
                description:productInfo.description,
                quantity:1
            },
            ...cartItems]
            )
        }
        else{
                let newCartArray = [...cartItems]
                newCartArray[productIndexInCart].quantity++
                setCartItems(newCartArray)
        }
          setNumInCart(numInCart+1)
    }
    const handleSubtract = () => {
        

        if(cartItems[productIndexInCart].quantity === 1 ){
            let newCartArray = [...cartItems]
            newCartArray.splice(productIndexInCart,1)
        }
        else{
            let newCartArray = [...cartItems]
            newCartArray[productIndexInCart].quantity--
            setCartItems(newCartArray)
        }
        setNumInCart(numInCart-1)
    }
    return (
        <div>
            {
                (numInCart === 0) ?
                    <div onClick={handleAdd}>加入購物車</div> :
                    <div>
                        <span onClick={handleSubtract}>-</span>
                        數量 {numInCart}
                        <span onClick={handleAdd}>+</span>
                    </div>
            }

        </div>
    )
}
