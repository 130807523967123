import React, { memo } from 'react'

import { Link } from 'react-router-dom'
import { MenuStyleItem } from './MenuItemStyle';


const MenuItem = () => {


  return (
    //  <li className="menu-item" ref={ref}>{props.children}</li>
    <nav>
      <MenuStyleItem color={"blue"}> <Link to="/" > Home Page</Link></MenuStyleItem>
      <MenuStyleItem> <Link to="/checkout" >Checkout</Link></MenuStyleItem>
      <MenuStyleItem> <Link to="/" >Product List</Link></MenuStyleItem>
      <MenuStyleItem> <Link to="/login" >login</Link></MenuStyleItem>
    </nav>
  )
}


export default memo(MenuItem);