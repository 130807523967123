import { useState, useEffect, useRef } from 'react'

const useMouse = () => {
    const [mousePos, setMousePos] = useState([0,0])
    const mouseListener = useRef((event) => {
        setMousePos([event.pageY,event.pageX])
    })
    useEffect(() => {
        const callback = mouseListener.current
        window.addEventListener('mousemove', callback)
        return () => {
            // componenet remove listener
            window.removeEventListener('mousemove', callback)
        }
    }, [])
    return mousePos
}
export default useMouse