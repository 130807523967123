import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import QuantityBtn from './QuantityBtn'
import Title from './Title'

export default function ProductDetail() {
  let params = useParams()
  let [productDetail, setProductDetail] = useState(null)
  useEffect(() => {

    fetch('https://hoyinleung.github.io/demoapi/react-basic-product.json')
      .then(response => response.json())
      .then(data => {

        let productInfo = data.find((element) => {
          return element.id === parseInt(params.id)
        })
        setProductDetail(productInfo)
      })
  }, []) /* <== Dependency array */



  return (
    <div>
      {
        productDetail &&
        <div>
          <Title mainTitle={'Product detail # ' + params.id} />
          <img src={process.env.PUBLIC_URL+'/img/'+productDetail.image} width="400px"></img>
          <p>{productDetail.name}</p>
          <p>{productDetail.price}</p>
          <QuantityBtn productInfo={productDetail}/>
          <Link to="/"> Back to Product Page</Link>
        </div>
      }

    </div>

  )
}
