import React,{useContext} from 'react'
import { LoginContext } from '../context/LoginContext';


export default function Version(props) {

    const {accountContext , setAccountContext} = useContext(LoginContext)
    const bottomStyle = {

        backgroundColor: "DodgerBlue",
        position: "fixed",
        bottom: 0

    };
    const displayData = data => `, Data:  ${data}`
    return (
        <div style={bottomStyle}>
            Version : {props.version} {props.data && <span>{displayData(props.data)}</span>}
            {props.children}

            {accountContext}
        </div>
    )
}
