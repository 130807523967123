import React, { useState, useEffect, useRef, useContext } from 'react'
import { LoginContext } from '../context/LoginContext';
import useLocalStorage from '../util/useLocalStorage';
import useMouse from '../util/useMouse';
import useUpdateLogger from '../util/useUpdateLogger';
import MenuItem from './MenuItem';

import Version from './Version';

export default function LoginForm() {
  const [name, setName] = useLocalStorage('name', '')
  const [value, setValue] = useLocalStorage('value', '')
  const {accountContext , setAccountContext} = useContext(LoginContext)
  // const menuItemRef = useRef(undefined)
  // useEffect(() => {
  //   if (menuItemRef.current) {
  //     console.log(`text value ${text}`)
  //     console.log(
  //       `menuItem text is : ${menuItemRef.current.textContent}`
  //     )

  //   }
  // }, [text, menuItemRef])

  // const [mouseYPos,setMouseYPos] = useState(0)
  const mousePos = useMouse()
  const [x, y] = mousePos
  useEffect(() => {

  }, [mousePos])

 
  
  // useUpdateLogger(name)
  // useUpdateLogger(value)
  return (
    <>
      {/* <MenuItem ref={menuItemRef}>{text}</MenuItem> */}
      {/* <button onClick={() => {
        setName('setted jc')
      }}>
        click me
      </button> */}
      <div>Cursor {x}, {y}</div>
      {/* <MouseYListener handleMouseMove={setMouseYPos}/> */}
      Name :<input
        value={accountContext}
        onChange={(e)=> {
          // console.log();
          setAccountContext(e.target.value)
          setName(e.target.value)
        }
          // setName(e.target.value)
        }
      />
      Value:  <input
        value={value}
        onChange={(e) => {
          console.log();
        }
          // setValue(e.target.value)
        }
      />
      <Version version="4" />
    </>

  )
}
