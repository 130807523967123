import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import styles from './ProductList.module.css'
import QuantityBtn from './QuantityBtn';
import Title from './Title';
export default function ProductList() {
    // let productList =
    //     [
    //         {"id":1,name:"apple",price:100,image:"apple.jpeg",description:"test apple"},
    //         {"id":2,name:"orange",price:100,image:"orange.jpeg",description:"test orange"},
    //         {"id":3,name:"mango",price:100,image:"mango.png",description:"test mango"},
    //         {"id":4,name:"test4",price:100,image:"cat.png",description:"test procduct4"},
    //     ]
    let [productList, setShowProductList] = useState([]);
    let [input, setInput] = useState('');


    useEffect(() => {
        /* 
        1： 無第二個参數： component每次render都會觸發
        2: Denpendency Array 是空array 時: 只會在第一次網頁render 時會觸發
        3: Denpendency Array 是有變數 ： 第一次網頁render時 ＋指定的變數改變 會觸發
        */
        downloadProduct()
    }, []) /* <== Dependency array */

    useEffect(() => {
        console.log(`input ${input}`)
        if (input.length < 4)
            console.log('too short')
        else
            console.log('normal')
    }, [input])
    const downloadProduct = () => {
        fetch('https://hoyinleung.github.io/demoapi/react-basic-product.json')
            .then(response => response.json())
            .then(data => {
   
                console.log(data)
                setShowProductList(data);
            })
    }
    return (
        /* React Frament */
        <>

            {/* {showProduct && <button onClick={()=>{setShowProduct(false)}}>hide </button>}
            {!showProduct && <button onClick={()=>{setShowProduct(true)}}>show </button>} */}

            <Title mainTitle="please choose fruit" />
            <input type='text' onChange={(e) => setInput(e.target.value)}></input>
           <button onClick={() => downloadProduct()}>Click to  Download</button>
           <button onClick={() => setShowProductList([])}>Click to  Clean</button>
            <img src={'/img/ivyso.jpeg'} width="200px"></img>
            <div>
                {
                    productList.map(product => (
                        <div className={styles.productBorder} key={product.id}>
                            {product.name}<br />
                            {product.price}<br />
                            <Link to={"/product/" + product.id}>

                                <img src={process.env.PUBLIC_URL+'/img/'+ product.image} width="200px" ></img>
                            </Link>
                            {product.desctiption}<br />
                            <QuantityBtn productInfo= {product}/>
                        </div>
                    ))
                }
            </div>

        </>

    )
}
