import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import ProductList from './component/ProductList';
import Checkout from './component/Checkout';
import ProductDetail from './component/ProductDetail';
import NotFound from './pages/NotFound';
import { CartContext } from './context/CartContext';
import { useContext, useRef, useState } from 'react'
import LoginForm from './component/LoginForm';
import { LoginContext } from './context/LoginContext';
import useLocalStorage from './util/useLocalStorage';
import MenuItem from './component/MenuItem';

function App() {
  const [name, setName] = useLocalStorage('name', '')
  const [localCartItem, setLocalCartItem] = useLocalStorage('cart', '')
  const [cartItems, setCartItems] = useState(localCartItem)
  const [account, setAccount] = useState(name)
  // const {accountContext , setAccountContext} = useContext(LoginContext)
  return (

    <BrowserRouter>
      <LoginContext.Provider value={
        {
          accountContext: account,
          setAccountContext: setAccount
        }
      }
      >

        <CartContext.Provider value={{ cartItems, setCartItems }}>
          <MenuItem />
          <p>Current Name : {account}</p>
          <Routes>
            <Route path="/" exact element={<ProductList />} />
            <Route path="/checkout" exact element={<Checkout />} />
            <Route path="/product" exact element={<ProductDetail />}>
              <Route path=":id" exact element={<ProductDetail />} />
            </Route>
            <Route path="/login" exact element={<LoginForm />} />
            {/* <Route path="/chargerlist" exact element={<ChargerContent />} /> */}
            <Route path="*" exact element={<NotFound />} />
          </Routes>
        </CartContext.Provider>
      </LoginContext.Provider>

    </BrowserRouter>


  );
}

export default App;
